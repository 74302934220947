import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { connect } from 'react-redux';
import { IAppointmentReducer } from '../../../../../core/appointments/components/store/reducer';
import renderFormField, {
  FormField,
} from '../../../../../core/records/components/Forms/FormFields';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../core/schemas/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
interface Props {
  saveData?: any;
  passDataToParent: any;
  appointmentReducer: IAppointmentReducer;
  schemaReducer: any;
  getSchema: Function;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

const NETOMNIA = [
  'Blockage Clearance Required',
  'BT Infrastructure Issues',
  'CBT Issues',
  'Costs Unviable',
  'Dry Installation',
  'MDU',
  'No Clear Installation Route',
  'Overlay Required',
  'Specialist Installation Required',
  'Wayleave Issue',
  'Install Not Attended',
  'Health & Safety Concern',
  'Calendar closed',
  'Engineer did not attend',
  'Calendar overbooked',
  'Netomnia - Not Yet RFS',
  'Netomnia - Installation Brought Forward',
];
const CUSTOMER = [
  'Cancellation for Personal Reasons',
  'Change of Mind',
  'Installation Bought Forward',
  'Moving Premises',
  'Not Happy with External Installation Route',
  'Request to Delay Install',
  'Still in Contract',
  'Uncontactable/Nobody Home',
  'Unhappy with Installation Method',
  'Install Reminder Rescheduled',
];
const YOUFIBRE = [
  'Claiming Misselling',
  'Duplicate Order',
  'Incorrect Install Date Booked',
  'No Backhaul',
  'Service Call No Longer Needed',
  'YouFibre - Not Yet RFS',
  'YouFibre - Installation Brought Forward',
];

class ChangeReasonForm extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const { schemaReducer, getSchema } = this.props;

    // Check for shortlist schema or fallback to API request
    const shortListChangeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );
    if (!shortListChangeReasonSchema) {
      getSchema({
        moduleName: FIELD_SERVICE_MODULE,
        entityName: 'ChangeReason',
      });
    }
  }

  constructFormFields = (col: any) => {
    const { appointmentReducer, schemaReducer } = this.props;

    const changeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );

    const appointmentReducerSchemaTypeId = changeReasonSchema?.types.find(
      (item: any) => item.name == appointmentReducer.schemaType,
    )?.id;

    if (
      col?.schemaTypeId &&
      col?.schemaTypeId !== appointmentReducerSchemaTypeId
    ) {
      return;
    }
    let options = col.options;
    if (col.name === 'CancelReason' || col.name === 'RescheduleReason') {
      let filter: any;
      if (this.formRef.current?.getFieldsValue().RequestedBy === 'NETOMNIA') {
        filter = NETOMNIA;
      } else if (
        this.formRef.current?.getFieldsValue().RequestedBy === 'CUSTOMER'
      ) {
        filter = CUSTOMER;
      } else if (
        this.formRef.current?.getFieldsValue().RequestedBy === 'YOUFIBRE'
      ) {
        filter = YOUFIBRE;
      }
      options = options?.filter((array: any) =>
        filter?.some((el: any) => el === array.label),
      );
    }

    const isRequired: boolean = !!col?.validators?.find(
      (validation: any) => validation.type === 'REQUIRED',
    );

    const initialValue = null;
    const field: FormField = {
      id: col.id ? col.id.toString() : col.name,
      schemaId: undefined,
      entity: 'ChangeReason',
      type: col.type,
      isHidden: col.isHidden ? col.isHidden : false,
      name: col.name,
      label: col.label || col.name,
      description: col.description,
      options: options,
      required: isRequired,
      defaultValue: !initialValue ? col.defaultValue : null,
      initialValue,
      isDisabled: false,
      handleInputChange: this.handleInputChange,
    };
    return renderFormField(field);
  };

  handleInputChange = async (params: any) => {
    const { saveData, passDataToParent } = this.props;
    try {
      await this.formRef.current?.validateFields();
      const formErrors = this.formRef.current
        ? this.formRef.current.getFieldsError()
        : [];
      const hasErrors =
        formErrors.filter(({ errors }) => errors.length).length > 0;
      if (
        !hasErrors &&
        this.formRef.current?.getFieldsValue()?.Reason !==
          'select cancellation reason'
      ) {
        passDataToParent(false);
        saveData(this.formRef.current?.getFieldsValue());
      } else {
        passDataToParent(true);
      }
    } catch (e) {
      passDataToParent(true);
      console.error(e);
    }
  };

  renderForm() {
    const changeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      this.props.schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );

    return (
      <Form
        style={{ maxHeight: 500, overflow: 'auto' }}
        ref={this.formRef}
        autoComplete="off"
        key={changeReasonSchema?.id}
        name={changeReasonSchema?.id}
      >
        <Row>
          {changeReasonSchema?.columns?.map((data: any) => (
            <Col>{this.constructFormFields(data)}</Col>
          ))}
        </Row>
      </Form>
    );
  }

  render() {
    return <>{this.renderForm()}</>;
  }
}

const mapState = (state: any) => ({
  appointmentReducer: state.appointmentReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

// @ts-ignore
export default connect(mapState, mapDispatch)(ChangeReasonForm);
